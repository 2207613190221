import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import HeroSection from "../components/hero-section";
import FeatureSection1 from "../components/feature-section-1";
import SectionLayout6 from "../components/section-layout-6";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogCard from "../components/blogCard";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { Container } from "react-bootstrap";
import uuid4 from "uuid4";
import Button from "react-bootstrap/Button";
import { StaticImage } from "gatsby-plugin-image";

const ResourcePage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "resources" }) {
				seoFieldGroups {
					metaTitle
					metaDescription
					# localBusinessSchema
					openGraphDescription
					openGraphTitle
					# productSchema
					image {
						sourceUrl
						altText
						publicUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
				pageFields {
					pageSections {
						... on WpPage_Pagefields_PageSections_HeroSection {
							fieldGroupName
							heading
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_FeatureSection1 {
							fieldGroupName
							alignment
							body
							heading
							hasQuote
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							button {
								target
								title
								url
							}
							quoteDetails {
								name
								quote
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
								fieldGroupName
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout6 {
							fieldGroupName
							heading
							button {
								target
								title
								url
							}
						}
					}
				}
			}
			allWpPost {
				nodes {
					title
					slug
					terms {
						nodes {
							... on WpCategory {
								id
								name
								parentId
							}
						}
					}
					postFields {
						fieldGroupName
						body
						authorName
						authorImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
										width: 140
									)
								}
							}
						}
						featureImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
										width: 140
									)
								}
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const [numOfItemsToShow, setNumberOfItems] = useState(3);
	const {
		pageData: {
			seoFieldGroups,
			pageFields: { pageSections },
		},
		allWpPost,
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	const catName = (catList) => {
		let outStr = "";
		if (catList.length > 0) {
			let allCat = catList.filter((item) => item.parentId);
			if (allCat.length === 0) {
				allCat = catList;
			}
			allCat.forEach(
				(ele, idx) =>
					(outStr += `${ele.name} ${idx < allCat.length ? "" : ", "}`)
			);
		}

		return outStr;
	};

	const sectionLayout6 = () => {
		let reviewSection;
		if (pageSections.length > 0) {
			reviewSection = pageSections.filter(
				(item) =>
					item.fieldGroupName == "Page_Pagefields_PageSections_SectionLayout6"
			);
		}

		if (reviewSection.length > 0) {
			return (
				<SectionLayout6
					heading={<SafeHtmlParser htmlContent={reviewSection[0]?.heading} />}
					ctaButton={reviewSection[0]?.button}
				/>
			);
		}
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					{pageSections.length > 0 &&
						pageSections.map((sectionInfo) => (
							<div key={uuid4()}>
								{sectionInfo.fieldGroupName ===
									"Page_Pagefields_PageSections_HeroSection" && (
									<HeroSection
										title={sectionInfo?.heading}
										backgroundImage={
											sectionInfo.backgroundImage.localFile.childImageSharp
												.gatsbyImageData
										}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"Page_Pagefields_PageSections_FeatureSection1" && (
									<FeatureSection1
										title={sectionInfo?.heading}
										text={<SafeHtmlParser htmlContent={sectionInfo?.body} />}
										backgroundImage={
											sectionInfo?.backgroundImage?.localFile.childImageSharp
												.gatsbyImageData
										}
										quoteImage={
											sectionInfo.quoteDetails?.image?.localFile.childImageSharp
												.gatsbyImageData
										}
										quoteName={sectionInfo?.quoteDetails?.name}
										quoteText={sectionInfo?.quoteDetails?.quote}
										hasQuote={sectionInfo?.hasQuote}
										alignemnt={sectionInfo.alignment}
										ctaButton={sectionInfo?.button}
									/>
								)}
							</div>
						))}
					{allWpPost.nodes && allWpPost.nodes.length > 0 && (
						<Container>
							<Row className="my-7">
								{allWpPost.nodes.slice(0, numOfItemsToShow).map((postItem) => (
									<Col lg={4} key={uuid4()}>
										<BlogCard
											title={postItem?.title}
											image={
												postItem.postFields.featureImage?.localFile
													.childImageSharp.gatsbyImageData
											}
											uri={`/resources/${postItem.slug}`}
											category={catName(postItem.terms?.nodes)}
											imageAlt={"blog"}
										/>
									</Col>
								))}
							</Row>
							{allWpPost.nodes && numOfItemsToShow < allWpPost.nodes.length && (
								<Button
									size="lg"
									className="px-5 py-3 robotosarif-medium fs-6 bg-transparent border-secondary my-7 d-flex align-items-center mx-auto text-secondary text-uppercase "
									onClick={() =>
										setNumberOfItems(numOfItemsToShow + numOfItemsToShow)
									}
									variant="secondary"
								>
									See More
									<StaticImage
										className="ms-3"
										src="../images/arrow-yellow.svg"
										formats={["auto", "webp"]}
										alt="arrow"
										style={{ width: "34px" }}
									/>
								</Button>
							)}
						</Container>
					)}
					<div className="my-7">{sectionLayout6()}</div>
				</Layout>
			</div>
		</>
	);
};

export default ResourcePage;
