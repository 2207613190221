import React from "react";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Button from "react-bootstrap/Button";
import { RiDoubleQuotesL } from "react-icons/ri";
import { SafeHtmlParser } from "./safe-html-parser";

const FeatureSection1 = ({
	title,
	text,
	ctaButton,
	alignemnt,
	hasQuote,
	quoteImage,
	quoteName,
	quoteText,
	backgroundImage,
	hasReverseInMobile
}) => {
	const pluginImage = getImage(backgroundImage);
	return (
		<section>
			<Row className={hasReverseInMobile ? 'flex-column-reverse flex-lg-row' : ''}>
				<Col lg={5} xl={4} className="p-0 bg-schema-bg">
					{hasQuote && (
						<div className="py-4 ps-4 ps-md-5 ps-xl-7 pe-4">
							{quoteImage && (
								<div className="text-center text-md-start">
									<GatsbyImage
										image={quoteImage}
										style={{ width: "180px" }}
										className="rounded-circle my-3"
										alt={quoteName}
									/>
								</div>
							)}
							<div className="quote-text fs-6 pt-4 pb-2">
								{quoteName && <RiDoubleQuotesL className="fs-2 mb-2" />}
								<SafeHtmlParser htmlContent={quoteText} />
							</div>
							{quoteName && (
								<span className="robotoserif-bold fst-italic mb-2 d-flex">
									{quoteName}
								</span>
							)}
						</div>
					)}
				</Col>
				<Col
					className="bg-primary p-0 feature-content"
					lg={{ span: 7, order: alignemnt === "Right" ? "last" : "first" }}
					xl={8}
				>
					<div className="h-100 d-flex align-items-center">
						<BgImage
							Tag="div"
							className="h-100 d-flex align-items-center"
							image={pluginImage}
							style={{
								backgroundSize: "contain",
								backgroundPosition: "right 80px top 0",
							}}
						>
							<div className="px-4 px-md-5 px-xl-7 py-6">
								<h2 className="mb-0 text-white">{title}</h2>
								<div className="text-white mt-4 content-roboto w-md-75">
									{text}
								</div>
								{ctaButton && ctaButton.url && (
									<Button
										as={Link}
										href={ctaButton?.url}
										rel="noreferrer"
										variant="secondary"
										className="w-100 w-md-auto py-3 px-5 px-md-6 mt-4 text-black robotoserif-light"
										target={ctaButton?.target ?? "_self"}
									>
										{ctaButton?.title}
									</Button>
								)}
							</div>
						</BgImage>
					</div>
				</Col>
			</Row>
		</section>
	);
};

export default FeatureSection1;
